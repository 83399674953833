import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import BidifyFactory from './jsons/BidifyMarket.json';
const axios = require('axios'); // Import axios if you haven't already

const contractAddress = '0xc851f209015b323AA67e7b1325148E1079e4D205'; // Replace with your contract address
const abi = BidifyFactory.abi; // Use the ABI from the imported JSON file

function AuctionList() {
  const [bidLogs, setBidLogs] = useState([]);
  const [extendedLogs, setExtendedLogs] = useState([]);
  const [finishedLogs, setFinishedLogs] = useState([]);

  useEffect(() => {
    async function fetchBidLogs() {
      try {
        if (window.ethereum) {
          await window.ethereum.enable(); // Request user permission to connect
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(contractAddress, abi, provider);
          const bidEventFilter = contract.filters.Bid(); // Event filter for "Bid" events
          const bidLogs = await provider.getLogs({
            fromBlock: 0,
            toBlock: 'latest',
            topics: bidEventFilter.topics,
          });
  
          const formattedBidLogs = bidLogs.map((log) => {
            const decodedLog = contract.interface.parseLog(log);
            const auctionId = decodedLog.args.id.toNumber();
            const bidder = decodedLog.args.bidder;
            const priceInWei = decodedLog.args.price;
  
            // Format the price from Wei to Ether
            const priceInEther = ethers.utils.formatEther(priceInWei);
  
            // Include all columns here
            return { auctionId, bidder, priceInEther }; // Make sure to include all necessary columns
          });
  
          // Now, you can send all the formattedBidLogs to your API for insertion
          const apiEndpoint = 'https://api.bidify.app/api/insertbidlogs'; // Correct API endpoint
          const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formattedBidLogs),
          });
  
          if (response.ok) {
            console.log('Bid logs inserted successfully');
  
            // After successful insertion, call the update API for each bid log
            for (const bidLog of formattedBidLogs) {
              const updateApiEndpoint = `https://api.bidify.app/api/updatecurrentbid/${bidLog.auctionId}`;
              const updateResponse = await fetch(updateApiEndpoint, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newBid: bidLog.priceInEther }),
              });
  
              if (updateResponse.ok) {
                console.log(`Current bid updated successfully for auctionId ${bidLog.auctionId}`);
              } else {
                console.error(`Error updating current bid for auctionId ${bidLog.auctionId}:`, updateResponse.statusText);
              }
            }
          } else {
            console.error('Error inserting bid logs:', response.statusText);
          }
  
          setBidLogs(formattedBidLogs);
        } else {
          console.error('MetaMask not detected.');
        }
      } catch (error) {
        console.error('Error fetching and inserting bid logs:', error);
      }
    }
  
    
    

    async function fetchExtendedLogs() {
      try {
        if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(contractAddress, abi, provider);
          const extendedEventFilter = contract.filters.AuctionExtended(); // Event filter for "AuctionExtended" events
          const extendedLogs = await provider.getLogs({
            fromBlock: 0,
            toBlock: 'latest',
            topics: extendedEventFilter.topics,
          });

          const formattedExtendedLogs = extendedLogs.map((log) => {
            const decodedLog = contract.interface.parseLog(log);
            const auctionId = decodedLog.args.id.toNumber();
            const time = decodedLog.args.time.toNumber();

            return { id: auctionId, time };
          });

          setExtendedLogs(formattedExtendedLogs);
        } else {
          console.error('MetaMask not detected.');
        }
      } catch (error) {
        console.error('Error fetching extended logs:', error);
      }
    }

    async function fetchFinishedLogs() {
      try {
        if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(contractAddress, abi, provider);
          const finishedEventFilter = contract.filters.AuctionFinished(); // Event filter for "AuctionFinished" events
          const finishedLogs = await provider.getLogs({
            fromBlock: 0,
            toBlock: 'latest',
            topics: finishedEventFilter.topics,
          });

          const formattedFinishedLogs = finishedLogs.map((log) => {
            const decodedLog = contract.interface.parseLog(log);
            const auctionId = decodedLog.args.id.toNumber();
            const nftRecipient = decodedLog.args.nftRecipient;
            const priceInWei = decodedLog.args.price;

            // Format the price from Wei to Ether
            const priceInEther = ethers.utils.formatEther(priceInWei);

            return { id: auctionId, nftRecipient, price: priceInEther };
          });

          setFinishedLogs(formattedFinishedLogs);
        } else {
          console.error('MetaMask not detected.');
        }
      } catch (error) {
        console.error('Error fetching finished logs:', error);
      }
    }

    fetchBidLogs();
    fetchExtendedLogs();
    fetchFinishedLogs();
  }, []);

  return (
    <div className="container mx-auto p-8">
      <h2 className="text-2xl font-bold mb-4">Bid Logs</h2>
      <ul className="list-disc pl-8">
        {bidLogs.map((log, index) => (
          <li key={index} className="mb-2">
            Auction ID: {log.auctionId}, Bidder: {log.bidder}, Price: {log.price} ETH
          </li>
        ))}
      </ul>

      <h2 className="text-2xl font-bold my-4">Extended Logs</h2>
      <ul className="list-disc pl-8">
        {extendedLogs.map((log, index) => (
          <li key={index} className="mb-2">
            Auction ID: {log.id}, Time Extended: {log.time}
          </li>
        ))}
      </ul>

      <h2 className="text-2xl font-bold my-4">Finished Logs</h2>
      <ul className="list-disc pl-8">
        {finishedLogs.map((log, index) => (
          <li key={index} className="mb-2">
            Auction ID: {log.id}, NFT Recipient: {log.nftRecipient}, Price: {log.price} ETH
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AuctionList;
