import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import BidifyMarket from './jsons/BidifyMarket.json';
import { Web3Storage } from 'web3.storage';
import { Outlet, Link } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import NFTList from './NFTList';
import NFTBanner from './NFTBanner';
import Footer from './Footer'; // Adjust the path based on your project structure


const contractAddresses = {
  '0x1': '0xMainnetContractAddress', // Mainnet
  '0x3': '0xRopstenContractAddress', // Ropsten
  '0x4': '0xRinkebyContractAddress', // Rinkeby
  '0x5': '0xGoerliContractAddress', // Goerli
  '0x89': '0x2FccEd65EeC83Bf2790bBc046013e13d6498038C',   // Kovan
  '0x13881': '0xc851f209015b323AA67e7b1325148E1079e4D205', // Polygon Mumbai
  '80001': '0xc851f209015b323AA67e7b1325148E1079e4D205', // Also for Polygon Mumbai
  // Add more network addresses as needed
};



function AuctionList() {
  const [auctions, setAuctions] = useState([]);
  const web3StorageToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweERDNGZmRmU5RkQwOEQ4YzI2M0YyODZmODgzMjg2ZmVmRDA0MTFmZjYiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2OTIxMzgxMDU1NjksIm5hbWUiOiJ0ZXN0YXBpIn0.VPeUucdI_jMcdkMmzghr95IM6vdYeThZ5CEUcdLfoJQ'; // Replace with your actual Web3Storage token
  const web3StorageClient = new Web3Storage({ token: web3StorageToken });
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [chainName, setChainName] = useState(null);

  
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(accounts => {
          setAccount(accounts[0]);
          setChainId(window.ethereum.chainId);
          setChainName(getChainNamefromhex(window.ethereum.chainId));
        })
        .catch(error => {
       //   alert('Error connecting to MetaMask: ' + error);
        });
  
      window.ethereum.on('chainChanged', newChainId => {
        setChainId(newChainId);
        setChainName(getChainNamefromhex(newChainId));
  
        // Reload the page when the network changes
        window.location.reload();
      });
    } else {
      alert('MetaMask not detected.');
    }
    
// Replace 'contractAddress' with the dynamic address based on the current network
const currentNetworkAddress = contractAddresses[chainId];
const contractAddress = currentNetworkAddress || '0xc851f209015b323AA67e7b1325148E1079e4D205'; // Replace '0xDefaultContractAddress' with a default if the chain ID is not recognized
//const contractAddress = '0xc851f209015b323AA67e7b1325148E1079e4D205'; // Replace with your contract address
const abi = BidifyMarket.abi; // Use the ABI from the imported JSON file
    const getChainNamefromhex = chainId => {
      switch (chainId) {
        case '0x1':
          return 'Mainnet';
        case '0x3':
          return 'Ropsten';
        case '0x4':
          return 'Rinkeby';
        case '0x5':
          return 'Goerli';
        case '0x2a':
          return 'Kovan';
        case '0x38':
          return 'BNB Smart Chain Mainnet';            
        case '0x7c3':
          return 'EtherGem';
        case '0xa86a':
          return 'Avalanche C-Chain';
        case '0x89':
          return 'MATIC';
        case '0x13881':
          return 'Matic Mumbai';
        case '0x64':
          return 'Gnosis';
        case '0x3d':
          return 'Ethereum Classic Mainnet'; 
        case '0x13881':
          return 'Matic Mumbai';
        case '0x505':
          return 'Evmos';
        default:
          return 'Unknown';
      }
    };
    

  
    async function fetchAuctions() {
      try {
        if (window.ethereum) {
          await window.ethereum.enable(); // Request user permission to connect
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const network = await provider.getNetwork(); // Get the network information
          const chainId = window.ethereum.chainId;
          const chainName = getChainNamefromhex(chainId);

            const contract = new ethers.Contract(contractAddress, abi, provider);
            const eventFilter = contract.filters.ListingCreated();
            
            // Get the latest block number
            const latestBlockNumber = await provider.getBlockNumber();
            
            // Set the range to fetch logs from the latest block to the last 1000 blocks
            const fromBlock = Math.max(0, latestBlockNumber - 1000);
            const toBlock = 'latest';
            
            // Fetch logs for the specified block range
            const logs = await provider.getLogs({
              fromBlock: fromBlock,
              toBlock: toBlock,
              topics: eventFilter.topics,
            });
            
            console.log(logs);
            
            const formattedAuctions = [];
    
          for (const log of logs) {
            const decodedLog = contract.interface.parseLog(log);
            const auctionId = decodedLog.args.id.toNumber();
            const platformContract = new ethers.Contract(decodedLog.args.platform, ['function name() view returns (string)', 'function symbol() view returns (string)', 'function tokenURI(uint256 tokenId) view returns (string)'], provider);
            const platformName = await platformContract.name();
            const platformSymbol = await platformContract.symbol();
            const tokenURI = await getCidTokenUri(platformContract, decodedLog.args.token);


            // Fetch auction details for each auction ID
            const auctionDetails = await contract.getListing(auctionId);
            console.log('Auction Details:', auctionDetails);
           // console.log('Auction Details:', auctionDetails);
    
          // Create an object with auction details
          const formattedAuction = {
            id: decodedLog.args.id.toNumber(),
            token: decodedLog.args.token.toString(),
            currency: decodedLog.args.currency,
            platform: auctionDetails[2],
            platformName: platformName,
            platformSymbol: platformSymbol,
            creator: decodedLog.args.creator,
            price: decodedLog.args.price.toString(),
            timeInDays: decodedLog.args.timeInDays,
            referrer: auctionDetails[7],
            tokenURI: tokenURI,
            highBidder: auctionDetails[8],
            allowMarketplace: auctionDetails[6],
            endTime : auctionDetails[9]._hex,
            paidOut: auctionDetails[10],
            isERC721: auctionDetails[11],
          };

      
          formattedAuctions.push(formattedAuction);

          // Insert data into the database for each formatted auction
          const dataToInsert = {
            auction_id: decodedLog.args.id.toNumber(),
            creator: formattedAuction.creator,
            currency: formattedAuction.currency, // Add the same property as in formattedAuction
            platform: formattedAuction.platform,
            platformName: formattedAuction.platformName, // Add the same property as in formattedAuction
            platformSymbol: formattedAuction.platformSymbol, // Add the same property as in formattedAuction
            token: formattedAuction.token,
            price: formattedAuction.price,
            timeInDays: formattedAuction.timeInDays,
            referrer: formattedAuction.referrer,
            tokenURI: formattedAuction.tokenURI,
            chainid: chainId, // Insert the chain ID here
            chainName: chainName, // Insert the chain ID here
            highBidder: formattedAuction.highBidder,
            allowMarketplace: formattedAuction.allowMarketplace,
            endTime: formattedAuction.endTime,
            paidOut: formattedAuction.paidOut,
            isERC721: formattedAuction.isERC721,
          };


            try {
              const response = await axios.post('https://api.bidify.app/api/insertauctions', dataToInsert);
              //  console.log(response.data.message); // Server response message
            } catch (error) {
              //    console.error('Error inserting data:', error);
            }
          }
    
          setAuctions(formattedAuctions); // Update state with fetched and formatted data
        } else {
          console.error('No web3 provider available.');
        }
      } catch (error) {
        console.error('Error fetching auctions:', error);
      }
    }
    
    fetchAuctions();
    
  }, []);

  const getCidTokenUri = async (platformContract, tokenId) => {
    try {
      const tokenURI = await platformContract.tokenURI(tokenId);
      const response = await web3StorageClient.get(tokenURI);
  
      if (!response.ok) {
        console.error(`Response was not ok: ${response.status}`);
        return null;
      }
  
      const files = await response.files();
      const firstFile = files[0];
  
      if (firstFile) {
        return `https://${firstFile.cid}.ipfs.w3s.link/`;
      } else {
        console.error("No file found in the CID.");
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  
 
return (
  
  <div className=''>
    <div className="bg-gray-50 flex items-center mb-10">
  <section
    className="w-full bg-cover bg-center py-32"
    style={{ backgroundImage: 'url("https://res.cloudinary.com/coin-nft/image/fetch/c_limit,q_auto,w_1800/f_auto/https://images.ctfassets.net/c5bd0wqjc7v0/6F9Yv56IB46upoNwtbA66K/1f5048e8a7c2c88dde8a8932584c797f/lofi3D.jpeg?_a=ATCkFAA0")' }}
  >
    <div className="container mx-auto text-center text-white">
      <h1 className="text-5xl font-medium mb-6">Bidify Nft</h1>
      <p className="text-xl font-bold mb-12">
      Bid, Buy and Sell

any NFT on the Ethereum Network
      </p>
      <a
        href="#"
        className="bg-blue-500 text-white py-4 px-12 rounded-full hover:bg-blue-600"
      >
        Get started
      </a>
    </div>
  </section>
</div>

      <NFTBanner chainId={chainId} />
    {chainId !== null ? (
      <NFTList chainId={chainId} />
    ) : (
<div className="flex items-center justify-center">
  <p className="text-center">Loading...</p>
</div>
    )}
          <Footer />

  </div>
);
}

export default AuctionList;
