import React, { useState, useEffect } from 'react';
import BidifyFactory from './jsons/BidifyFactory.json'; // Replace with the actual path to the compiled contract JSON
import { ethers } from 'ethers';
import { Web3Storage } from 'web3.storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MintCollection() {
  const [uri, setUri] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [collectionSymbol, setCollectionSymbol] = useState('');
  const [count, setCount] = useState(1); // Initialize the count to 1
  const [status, setStatus] = useState('');
  const [factoryContractAddress] = useState('0x0807D68b4Cd34BF4DEc7e03a7A21187d469337Be'); // Set the BidifyFactory contract address here
  const [factoryContract, setFactoryContract] = useState(null);
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [chainName, setChainName] = useState(null);

  useEffect(() => {


    if (window.ethereum) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(accounts => {
          setAccount(accounts[0]);
          setChainId(parseInt(window.ethereum.chainId, 16));
          setChainName(getChainNamefromhex(window.ethereum.chainId));
          
          // Log chain ID and chain name to the console
          console.log('Chain ID:', window.ethereum.chainId);
          console.log('Chain Name:', getChainNamefromhex(window.ethereum.chainId));
        })
        .catch(error => {
          console.error('Error connecting to MetaMask:', error);
        });
  
      window.ethereum.on('chainChanged', newChainId => {
        setChainId(newChainId);
        setChainName(getChainNamefromhex(newChainId));
        
        // Log chain ID and chain name to the console when it changes
        console.log('New Chain ID:', newChainId);
        console.log('New Chain Name:', getChainNamefromhex(newChainId));
  
        // Reload the page when the network changes
        window.location.reload();
      });
    } else {
      console.error('MetaMask not detected.');
    }
    
    const getChainNamefromhex = chainId => {
      switch (chainId) {
        case '0x1':
          return 'Mainnet';
        case '0x3':
          return 'Ropsten';
        case '0x4':
          return 'Rinkeby';
        case '0x5':
          return 'Goerli';
        case '0x2a':
          return 'Kovan';
        case '0x13881':
          return 'Polygon Mumbai';
        default:
          return 'Unknown';
      }
    };
  

    const getChainName = chainId => {
      switch (chainId) {
        case '80001':
          return 'Polygon Mumbai';
        default:
          return 'Unknown';
      }
    };


    async function initialize() {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(factoryContractAddress, BidifyFactory.abi, signer);

        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const accounts = await provider.listAccounts();
          setAccount(accounts[0]);
          setFactoryContract(contract);
          setStatus('Connected');
        } catch (error) {
          setStatus('Wallet connection error');
        }
      } else {
        setStatus('No Ethereum wallet detected');
      }
    }

    initialize();
  }, [factoryContractAddress]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUri(file);
    }
  };

  const mintToken = async () => {
    if (!account) {
      setStatus('Please connect your Ethereum wallet');
      return;
    }

    if (!uri || !collectionName || !collectionSymbol || count <= 0) {
      setStatus('Please provide all details');
      return;
    }

    try {
      console.log('URI to be uploaded:', uri); // Log the URI before uploading

      setStatus('Uploading image to IPFS via web3.storage...');
      const web3Storage = new Web3Storage({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweERDNGZmRmU5RkQwOEQ4YzI2M0YyODZmODgzMjg2ZmVmRDA0MTFmZjYiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2OTIxMzgxMDU1NjksIm5hbWUiOiJ0ZXN0YXBpIn0.VPeUucdI_jMcdkMmzghr95IM6vdYeThZ5CEUcdLfoJQ' }); // Replace with your API key

      const cid = await web3Storage.put([uri]);
      console.log('IPFS CID:', cid); // Log the CID after uploading

      setStatus('Minting token...');
      await factoryContract.mint(cid, count, collectionName, collectionSymbol, '0x0000000000000000000000000000000000000000', {
        value: ethers.utils.parseEther('0.00001') // 1 wei in ether
      });

  // After minting, insert data into the database via API
  const apiUrl = 'https://api.bidify.app/api/insertcollections'; // Adjust the URL to your API endpoint
  const requestBody = {
    owner_address: account, // Assuming 'account' holds the Ethereum wallet address
    collection_name: collectionName,
    collection_symbol: collectionSymbol,
    mint_count: count,
    ipfs_cid: cid,
    chain_id: chainId, // Replace with the actual chain ID
    chain_name: chainName, // Replace with the actual chain name
    created_at: new Date().toISOString() // Convert the date to ISO format
  };

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });

  if (response.ok) {
    console.log('Data inserted into the database via API');
    toast.success('Token minted successfully', { position: 'top-right', autoClose: 300000 });
  } else {
    console.error('Error inserting data into the database via API');
    setStatus('Error inserting data into the database via API');
  }
} catch (error) {
  console.error(error);
  setStatus('Error uploading image to IPFS via web3.storage or minting token');
}
};

return (
  <div className="bg-gradient-to-br from-purple-500 to-indigo-500 min-h-screen flex justify-center items-center">
    <div className="w-full max-w-4xl p-6 bg-white rounded-lg shadow-xl">
      <h1 className="text-3xl font-bold text-purple-900 mb-6">Bidify Token Minting App</h1>
      <div className="flex">
        <div className=" pr-6">
          <div className="mb-6">
            <p className="text-lg text-gray-700 mb-2">Status: {status}</p>
            {account && (
              <div>
                <p className="text-lg text-gray-700 mb-2">Connected Account: {account}</p>
                <input type="file" className="mb-2 bg-gray-100 p-3 rounded-lg" onChange={handleFileChange} />
                <input
                  type="number"
                  className="mb-2 p-3 w-full bg-gray-100 rounded-lg"
                  placeholder="Count"
                  value={count}
                  onChange={(e) => setCount(parseInt(e.target.value))}
                />
                <input
                  type="text"
                  className="mb-2 p-3 w-full bg-gray-100 rounded-lg"
                  placeholder="Collection Name"
                  value={collectionName}
                  onChange={(e) => setCollectionName(e.target.value)}
                />
                <input
                  type="text"
                  className="mb-2 p-3 w-full bg-gray-100 rounded-lg"
                  placeholder="Collection Symbol"
                  value={collectionSymbol}
                  onChange={(e) => setCollectionSymbol(e.target.value)}
                />
                <button
                  className="bg-purple-500 hover:bg-purple-600 text-white py-3 px-6 rounded-lg"
                  onClick={mintToken}
                >
                  Mint Token
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2 pl-6">
          <div className="mb-6">
            {uri && (
              <div className="mb-4">
                <h2 className="text-xl font-semibold text-purple-900">Image Preview</h2>
                <div className="w-48 h-48 mb-4">
                  <img src={URL.createObjectURL(uri)} alt="Preview" className="w-full h-full object-cover rounded-lg" />
                </div>
                <p className="text-xl font-semibold text-purple-900">{collectionName}</p>
                <p className="text-gray-500">{collectionSymbol}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <ToastContainer position="top-right" /> 

  </div>
);

}

export default MintCollection;
