import React, { useState, useEffect } from 'react';
import BidifyFactory from './jsons/BidifyFactory.json';
import BidifyToken from './jsons/BidifyToken.json'; // Import the ABI of BidifyToken contract
import { ethers } from 'ethers';
import { Web3Storage } from 'web3.storage';
import { Link } from 'react-router-dom';
import axios from 'axios';

function CollectionList() {
  const [userCollections, setUserCollections] = useState([]);
  const [account, setAccount] = useState('');
  const [factoryContractAddress] = useState('0x0807D68b4Cd34BF4DEc7e03a7A21187d469337Be');
  const [factoryContract, setFactoryContract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const web3StorageToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweERDNGZmRmU5RkQwOEQ4YzI2M0YyODZmODgzMjg2ZmVmRDA0MTFmZjYiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2OTIxMzgxMDU1NjksIm5hbWUiOiJ0ZXN0YXBpIn0.VPeUucdI_jMcdkMmzghr95IM6vdYeThZ5CEUcdLfoJQ'; // Replace with your actual Web3Storage token
  const web3StorageClient = new Web3Storage({ token: web3StorageToken });

  useEffect(() => {
    async function initialize() {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(factoryContractAddress, BidifyFactory.abi, signer);

        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const accounts = await provider.listAccounts();
          setAccount(accounts[0]);
          setFactoryContract(contract);

          const tokenAddress = await contract.token();
          const tokenContractInstance = new ethers.Contract(tokenAddress, BidifyToken.abi, provider);
          setTokenContract(tokenContractInstance);
        } catch (error) {
          console.error(error);
        }
      }
    }

    initialize();
  }, [factoryContractAddress]);

  useEffect(() => {
    fetchUserCollections();
  }, [account]);

  const fetchUserCollections = async () => {
    if (!account || !factoryContract) {
      return;
    }

    try {
      const collections = await factoryContract.getCollections();
      const updatedCollections = await Promise.all(collections.map(async collection => {
        const uri = collection[3];
        const imageUrl = /^(http|https):\/\//.test(uri) ? uri : await getCidImageUri(uri);

        const tokenAddress = collection[4];
        const tokenUri = await getTokenUri(tokenContract, tokenAddress);

        // Check if the collection is listed
        const isListed = await checkIfCollectionIsListed(collection.platform);

        return {
          platform: collection[0],
          name: collection[1],
          symbol: collection[2],
          uri,
          imageUrl,
          tokenUri,
          isListed, // Add the 'isListed' field to indicate if it's listed or not
        };
      }));
      setUserCollections(updatedCollections);
    } catch (error) {
      console.error(error);
    }
  };

  const getCidImageUri = async (cid) => {
    try {
      const response = await web3StorageClient.get(cid);
      const files = await response.files();
      const firstFile = files[0];

      if (firstFile) {
        return `https://${firstFile.cid}.ipfs.w3s.link/`;
      } else {
        console.error("No file found in the CID.");
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getTokenUri = async (contract, tokenAddress) => {
    try {
      const tokenURI = await contract.tokenURI(tokenAddress);
      const response = await web3StorageClient.get(tokenURI);
      const files = await response.files();
      const firstFile = files[0];

      if (firstFile) {
        return `https://${firstFile.cid}.ipfs.w3s.link/`;
      } else {
        console.error("No file found in the CID.");
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const checkIfCollectionIsListed = async (platformAddress) => {
    try {
      // Make an API request to check if the collection is listed
      const response = await axios.get(`https://api.bidify.app/api/auctions/platform/${platformAddress}`);
      return response.data.auctions.length > 0; // Check if there are any auctions for this platform
    } catch (error) {
      console.error(error);
      return false; // Assume it's not listed if there was an error
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-8">
    <div className="container mx-auto p-4">
        <h1 className="text-3xl font-semibold mb-6">Your Collections</h1>
        {userCollections.length === 0 ? (
          <p>you have no collections found in this network</p>
        ) : (
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
            {userCollections.map((collection, index) => (
              <Link
                key={index}
                to={`/collection/${collection.platform}`} // Pass platform address as a parameter
                className="hover:underline"
              >
                <div className="bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 cursor-pointer p-4">
                  {collection.imageUrl && (
                    <div className="mb-4">
                      <img
                        src={collection.imageUrl}
                        alt={`Collection ${index}`}
                        className="mb-3 w-full rounded-xl 3xl:h-full 3xl:w-full"
                      />
                    </div>
                  )}

                  <div className="mb-2">
                      <p className="text-lg font-bold text-navy-700">{collection.name} ({collection.symbol})</p>
                  </div>
                  <div className="">
                  <span className="text-gray-500 font-bold text-md">
                    {collection.platform.length >= 10 ? (
                      <>
                        {collection.platform.slice(0, 10)}...{collection.platform.slice(-5)}
                      </>
                    ) : (
                      collection.platform
                    )}
                  </span>
                  </div>
                  <div className="mt-4">
                    {collection.isListed ? (
                      <span className="px-2 py-1 bg-green-500 text-white rounded-full">Listed</span>
                    ) : (
                      <span className="px-2 py-1 bg-red-500 text-white rounded-full">Not Listed</span>
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
  
  
}

export default CollectionList;
