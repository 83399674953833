import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./pages/Navbar";
import Home from "./pages/Home";
import MintCollection from "./pages/MintCollection";
import CollectionList from "./pages/CollectionList";
import CollectionDetail from "./pages/CollectionDetail";
import Listing from './pages/Listing'; // Create this component
import Auctions from './pages/Auctions'; // Create this component
import BidLogs from './pages/BidLogs'; // Create this component
import MyBiddings from './pages/MyBiddings'; // Create this component

import AuctionDetail from './pages/AuctionDetail'; // Create this component

import NoPage from "./pages/NoPage";
import "./index.css";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="mintcollection" element={<MintCollection />} />
          <Route path="mycollections" element={<CollectionList />} />
          <Route path="listing" element={<Listing />} />
          <Route path="auctions" element={<Auctions />} />
          <Route path="bidlogs" element={<BidLogs />} />
          <Route path="mybids" element={<MyBiddings />} />
          <Route path="/auction/:id" element={<AuctionDetail />} />
          <Route path="/collection/:address" element={<CollectionDetail />} />

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);