import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import BidifyFactory from './jsons/BidifyMarket.json';
import BidifyToken from './jsons/BidifyToken.json';
import { Web3Storage } from 'web3.storage';
import Modal from 'react-modal';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CollectionDetail = () => {
  const { address } = useParams();
  const [collection, setCollection] = useState(null);
  const [token, setToken] = useState(0);
  const [price, setPrice] = useState('');
  const [timeInDays, setTimeInDays] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listingStatus, setListingStatus] = useState('');
  const [isListingLoading, setIsListingLoading] = useState(false);
  const [isAlreadyListed, setIsAlreadyListed] = useState(false);
  const currency = '0x0000000000000000000000000000000000000000';
  const [auctions, setAuctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const web3StorageToken = 'your_web3storage_token_here';
  const web3StorageClient = new Web3Storage({ token: web3StorageToken });

  // Fetch collection details
  useEffect(() => {
    async function fetchCollectionDetails() {
      try {
        if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const tokenContract = new ethers.Contract(address, BidifyToken.abi, provider);

          const name = await tokenContract.name();
          const symbol = await tokenContract.symbol();

          // Fetch token details (you may want to adjust this based on your needs)
          const tokenId = 0;
          const tokenOwner = await tokenContract.ownerOf(tokenId);
          const tokenUri = await tokenContract.tokenURI(tokenId);
          const response = await web3StorageClient.get(tokenUri);
          const files = await response.files();
          const firstFile = files[0];

          if (firstFile) {
            const tokenMetadata = `http://${firstFile.cid}.ipfs.w3s.link/`;

            setCollection({
              name,
              symbol,
              tokenMetadata,
            });
          } else {
            console.error(`No file found in the CID for token ${tokenId}.`);
          }
        } else {
          console.error('No web3 provider available.');
        }
      } catch (error) {
        console.error('Error fetching collection details:', error);
      }
    }

    fetchCollectionDetails();
  }, [address, web3StorageClient]);

  // Fetch auctions by platform
  useEffect(() => {
    axios.get(`https://api.bidify.app/api/auctions/platform/${address}`)
      .then((response) => {
        const auctionsData = response.data.auctions;
        setAuctions(auctionsData);
        setLoading(false);

        // Check if there are any results in the auctions data
        if (auctionsData && auctionsData.length > 0) {
          setIsAlreadyListed(true); // Set it to true if there are results
        }
      })
      .catch((error) => {
        console.error('Error fetching auctions by platform:', error);
        setLoading(false);
      });
  }, [address]);


  const handleListingSubmit = async (e) => {
    e.preventDefault();

    setIsListingLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const contractAddress = '0xc851f209015b323AA67e7b1325148E1079e4D205';
      const contract = new ethers.Contract(contractAddress, BidifyFactory.abi, signer);

      const tokenContractAddress = address;
      const tokenContract = new ethers.Contract(tokenContractAddress, BidifyToken.abi, signer);

      const callerAddress = await signer.getAddress();
      const tokenOwner = await tokenContract.ownerOf(token);
      const approvedAddress = await tokenContract.getApproved(token);

      if (callerAddress === tokenOwner || callerAddress === approvedAddress) {
        const approvalTx = await tokenContract.approve(contractAddress, token);
        const approvalTxReceipt = await approvalTx.wait();
        const approvalTxHash = approvalTxReceipt.transactionHash;
        const userAddress = await signer.getAddress(); // Get the user's Ethereum address

        const transaction = await contract.list(
          currency,
          tokenContractAddress,
          token,
          ethers.utils.parseEther(price.toString()).toString(),
          3, // Include the actual numeric endingPrice here
          timeInDays,
          true,
          userAddress // Pass the user's Ethereum address as the lister
        );
      
        await transaction.wait();
        setListingStatus('Listing created successfully');
        toast.success('Listing created successfully');

      } else {
        setListingStatus('Caller is not the owner nor approved');
      }
    } catch (error) {
      console.error(error);
      setListingStatus('Error creating listing');
      toast.error('Error creating listing');

    } finally {
      setIsListingLoading(false);
    }
  };

  if (!collection) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gray-100 min-h-screen py-8">
      <ToastContainer />
      <div className="container mx-auto">

      <div className="grid grid-cols-2 gap-4">
  <div>
    <img
      src={collection.tokenMetadata}
      alt={`Token 0`}
      className="max-w-500 h-500 rounded-lg mb-4"
    />
  </div>

  <div>
    <h1 className="text-3xl font-semibold mb-6">{collection.name} Collection</h1>
    <h1 className="text-3xl font-semibold mb-6">{collection.symbol}</h1>

    <button
      onClick={() => setIsModalOpen(true)}
      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
      disabled={isAlreadyListed}
    >
      {isAlreadyListed ? 'Already Listed' : 'List NFT'}
    </button>
  </div>
</div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Listing NFT"
          className="modal"
          overlayClassName="overlay"
        >
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">List NFT</h2>
            <form onSubmit={handleListingSubmit} className="space-y-4">
              <label className="block">
                Platform (ERC721 Contract):
                <input
                  type="text"
                  value={address}
                  className="w-full p-2 border rounded"
                  readOnly
                />
              </label>
              
              <input
                type="number"
                placeholder="Token ID"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                className="w-full p-2 border rounded"
              />
              <input
                type="number"
                placeholder="Price (ETH)"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="w-full p-2 border rounded"
              />
              
              <input
                type="number"
                placeholder="Time in Days"
                value={timeInDays}
                onChange={(e) => setTimeInDays(e.target.value)}
                className="w-full p-2 border rounded"
              />
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                disabled={isListingLoading}
              >
                {isListingLoading ? 'Listing...' : 'List NFT'}
              </button>
              <p className="mt-4">Status: {listingStatus}</p>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CollectionDetail;
