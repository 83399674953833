import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const NFTBids = () => {
  const [loading, setLoading] = useState(true);
  const [bidder, setBidder] = useState(''); // Initialize bidder with an empty string
  const [bidLogs, setBidLogs] = useState([]); // Initialize bidLogs as an empty array
  const [auctions, setAuctions] = useState([]); // Initialize auctions as an empty array
  const [creatorAddress, setCreatorAddress] = useState(''); // Initialize creatorAddress with an empty string

  useEffect(() => {
    // Check if MetaMask is available and connected
    if (window.ethereum) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(accounts => {
          setBidder(accounts[0]); // Use the connected Ethereum account as the bidder
          setCreatorAddress(accounts[0]); // Use the connected Ethereum account as the creatorAddress

          // Fetch data based on the creatorAddress (bidder)
          fetchAuctionsAndBidLogs(accounts[0]);

          // Log connected address to the console
          console.log('Connected Ethereum Account (Bidder):', accounts[0]);
        })
        .catch(error => {
          console.error('Error connecting to MetaMask:', error);
        });

      // Handle chain changes (if needed)
      window.ethereum.on('chainChanged', newChainId => {
        // Handle chain changes here
        // ...
      });
    } else {
      console.error('MetaMask not detected.');
    }
  }, []); // Empty dependency array to run this effect only once when the component mounts

  // Function to fetch auctions and bid logs based on creatorAddress (bidder)
  const fetchAuctionsAndBidLogs = (address) => {
    // Step 1: Fetch bid logs data
    axios.get('https://api.bidify.app/api/getbidlogs')
      .then((response) => {
        const bidLogsData = response.data;

        // Step 2: Filter bid logs by the current bidder (address)
        const filteredBidLogs = bidLogsData.filter((log) => log.bidder.toLowerCase() === address.toLowerCase());

        // Step 3: Extract unique auctionId values from filtered bid logs
        const uniqueAuctionIds = [...new Set(filteredBidLogs.map((log) => log.auctionId))];

        // Initialize an array to store fetched bid logs
        const fetchedBidLogs = [];

        // Step 4: Fetch bid logs for each unique auctionId
        const fetchBidLogsForAuctionIds = async () => {
          for (const auctionId of uniqueAuctionIds) {
            try {
              // Filter bid logs by the current auctionId
              const bidLogsForAuction = filteredBidLogs.filter((log) => log.auctionId === auctionId);

              // Add filtered bid logs to the array
              fetchedBidLogs.push(...bidLogsForAuction);
            } catch (error) {
              console.error(`Error fetching bid logs for auctionId ${auctionId}:`, error);
            }
          }

          // Set the fetched bid logs in state
          setBidLogs(fetchedBidLogs);

          // Step 5: Fetch auctions based on unique auctionIds
          fetchAuctionsForAuctionIds(uniqueAuctionIds);
        };

        // Call the function to fetch bid logs for unique auctionIds
        fetchBidLogsForAuctionIds();
      })
      .catch((error) => {
        console.error('Error fetching bid logs data:', error);
        setLoading(false);
      });
  };

  // Function to fetch auctions based on auctionIds
  const fetchAuctionsForAuctionIds = (auctionIds) => {
    // Step 6: Fetch auctions for each unique auctionId
    const fetchedAuctions = [];

    const fetchAuctions = async () => {
      for (const auctionId of auctionIds) {
        try {
          // Fetch auctions based on the current auctionId
          const auctionsResponse = await axios.get(`http://195.181.247.234:3000/api/auctions/${auctionId}`);

          // Add fetched auctions to the array
          fetchedAuctions.push(auctionsResponse.data.auction);
        } catch (error) {
          console.error(`Error fetching auctions for auctionId ${auctionId}:`, error);
        }
      }

      // Set the fetched auctions in state
      setAuctions(fetchedAuctions);
      setLoading(false);
    };

    // Call the function to fetch auctions for unique auctionIds
    fetchAuctions();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-6">
      <h2 className="text-2xl font-bold mb-4">Bid Logs</h2>
      <div className="bg-white overflow-auto shadow-sm rounded-lg mb-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Auction ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Bidder Address
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Bid Price
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {bidLogs.map((log) => (
              <tr key={log.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {log.auctionId}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {log.bidder}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {log.price}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h2 className="text-2xl font-bold mb-4">Auctions</h2>
      <div className="bg-white overflow-auto shadow-sm rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Auction ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Creator Address
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Token Price
              </th>
   
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Image
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {auctions.map((auction) => (
              <tr key={auction.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {auction.auction_id}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {auction.creator}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {auction.price}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <img src={auction.tokenURI} alt="Token" className="max-w-xs h-auto" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

};

export default NFTBids;
