import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

const NFTList = ({ chainId }) => {
  const [nfts, setNFTs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(''); // State for the search query

  useEffect(() => {
    // Make a GET request to your API endpoint with the chainId
    axios.get(`https://api.bidify.app/api/getallauctions/${chainId}`)
      .then((response) => {
        const uniqueNFTs = removeDuplicates(response.data.auctions, 'auction_id');
        setNFTs(uniqueNFTs);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false on error as well
      });
  }, [chainId]);

  // Function to remove duplicates based on a specific key
  const removeDuplicates = (array, key) => {
    const seen = new Set();
    return array.filter((item) => {
      const value = item[key];
      if (seen.has(value)) {
        return false;
      }
      seen.add(value);
      return true;
    });
  };

  // Function to handle changes in the search query
  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter NFTs based on the search query
  const filteredNFTs = nfts.filter((nft) =>
    nft.platformName.toLowerCase().includes(searchQuery.toLowerCase())
  );


// Shuffle the filteredNFTs array to display them randomly
const shuffledNFTs = filteredNFTs.sort(() => Math.random() - 0.5);

return (
  <div className='container mx-auto px-4'>
    <Swiper
      spaceBetween={10}
      slidesPerView={3} // Show 3 cards per row
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {shuffledNFTs.map((nft, index) => (
        <SwiperSlide key={index}>
          <div className="relative bg-white rounded-lg shadow-md overflow-hidden">
            {/* Full-size image */}
            <img
              src={nft.tokenURI}
              alt={`Image for ${nft.platformName}`}
              className="w-full h-96"
            />
            
            {/* Text overlay */}
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 text-white p-4 flex flex-col justify-end">
              <h2 className="text-xl font-semibold">{nft.platformName} ({nft.platformSymbol})</h2>
              <p className="text-sm">listed by {nft.creator}</p>
            </div>

            {/* Link */}
            <Link to={`/auction/${nft.auction_id}`} className="absolute top-0 left-0 w-full h-full"></Link>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);


  
};

export default NFTList;
