import React, { useState } from 'react';
import { ethers } from 'ethers';
import BidifyFactory from './jsons/BidifyMarket.json'; // Import the ABI of the factory contract
import BidifyTokenAbi from './jsons/BidifyToken.json'; // Import the ABI of the ERC721 token contract
import Modal from 'react-modal';
import './style.css'
function NFTListing() {
  const [status, setStatus] = useState('');
  const [currency, setCurrency] = useState('');
  const [platform, setPlatform] = useState('');
  const [token, setToken] = useState('');
  const [price, setPrice] = useState('');
  const [timeInDays, setTimeInDays] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsModalOpen(true); // Open the loading modal

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const contractAddress = '0xF6f7ab1DC7FAd7F8d03F13fC60F6EA0abb5c76b1'; // Replace with your contract address
    const contract = new ethers.Contract(contractAddress, BidifyFactory.abi, signer);

    const tokenContractAddress = platform; // Use the entered ERC721 contract address as the platform
    const tokenContract = new ethers.Contract(tokenContractAddress, BidifyTokenAbi.abi, signer);

    try {
      const callerAddress = await signer.getAddress();
      const tokenOwner = await tokenContract.ownerOf(token);
      const approvedAddress = await tokenContract.getApproved(token);

      // Check if the caller is either the owner of the token or approved
      if (callerAddress === tokenOwner || callerAddress === approvedAddress) {
        // Perform the approval of the NFT contract by the listing contract
        const approvalTx = await tokenContract.approve(contractAddress, token);

        // Wait for the approval transaction to be confirmed
        const approvalTxReceipt = await approvalTx.wait();
        const approvalTxHash = approvalTxReceipt.transactionHash;

        // Now create the listing
        const transaction = await contract.list(
          currency,
          platform, // Use the entered ERC721 contract address as the platform
          token,
          ethers.utils.parseEther(price.toString()).toString(), // Convert price to Ether
          timeInDays,
          '0x0000000000000000000000000000000000000000', // Replace with a valid address
          true, // Allow marketplace
          true // Assuming isERC721
        );

        await transaction.wait();
        setStatus('Listing created successfully');
      } else {
        setStatus('Caller is not the owner nor approved');
      }
    } catch (error) {
      console.error(error);
      setStatus('Error creating listing');
    } finally {
      setIsModalOpen(false); // Close the loading modal
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex justify-center items-center">
      <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-semibold mb-4">NFT Listing App</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input type="text" placeholder="Currency" value={currency} onChange={(e) => setCurrency(e.target.value)} className="w-full p-2 border rounded" />
          <input type="text" placeholder="Platform (ERC721 Contract)" value={platform} onChange={(e) => setPlatform(e.target.value)} className="w-full p-2 border rounded" />
          <input type="number" placeholder="Token ID" value={token} onChange={(e) => setToken(e.target.value)} className="w-full p-2 border rounded" />
          <input type="number" placeholder="Price (ETH)" value={price} onChange={(e) => setPrice(e.target.value)} className="w-full p-2 border rounded" />
          <input type="number" placeholder="Time in Days" value={timeInDays} onChange={(e) => setTimeInDays(e.target.value)} className="w-full p-2 border rounded" />
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
            List NFT
          </button>
        </form>
        <p className="mt-4">Status: {status}</p>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {}} // Empty function to prevent closing by clicking overlay
        contentLabel="Listing NFT"
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-loader">
          <div className="text-center">
            <div className="mb-2">Creating NFT listing...</div>
            <h1 className="text-2xl font-semibold">Please wait</h1>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NFTListing;
