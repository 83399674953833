import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const NFTSearch = ({ chainId }) => {
  const [nfts, setNFTs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(''); // State for the search query

  useEffect(() => {
    // Make a GET request to your API endpoint with the chainId
    axios.get(`https://api.bidify.app/api/getallauctions/${chainId}`)
      .then((response) => {
        const uniqueNFTs = removeDuplicates(response.data.auctions, 'auction_id');
        setNFTs(uniqueNFTs);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false on error as well
      });
  }, [chainId]);

  // Function to remove duplicates based on a specific key
  const removeDuplicates = (array, key) => {
    const seen = new Set();
    return array.filter((item) => {
      const value = item[key];
      if (seen.has(value)) {
        return false;
      }
      seen.add(value);
      return true;
    });
  };

  // Function to handle changes in the search query
  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter NFTs based on the search query
  const filteredNFTs = nfts.filter((nft) =>
    nft.platformName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-4">NFT List</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search NFTs by platform name..."
          className="border border-gray-300 rounded-md px-4 py-2"
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      </div>
      {(searchQuery && filteredNFTs.length > 0) ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
          {filteredNFTs.map((nft) => (
            <div
              key={nft.auction_id}
              className="bg-white rounded-lg shadow-md p-4"
            >
              <img
                src={nft.tokenURI}
                alt={`Image for ${nft.platformName}`}
                className="max-w-full h-auto"
              />
              <h2 className="text-xl font-semibold mb-2">{nft.platformName}</h2>
              <Link to={`/auction/${nft.auction_id}`}>See auction</Link>
              <p className="text-gray-600">Creator: {nft.creator}</p>
              <p className="text-gray-600">tokenid:{nft.token}</p>
            </div>
          ))}
        </div>
      ) : (
        searchQuery && <p>No NFTs found in this network.</p> // This line only displays when there are no search results and a search has been performed.
        )}
    </div>
  );
};

export default NFTSearch;
