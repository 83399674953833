import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import BidifyFactory from './jsons/BidifyMarket.json';
import { Web3Storage } from 'web3.storage';

const contractAddress = '0xc851f209015b323AA67e7b1325148E1079e4D205';
const abi = BidifyFactory.abi;


function AuctionDetailPage() {
  const { id } = useParams();
  const [auctionData, setAuctionData] = useState(null);
  const [bidAmount, setBidAmount] = useState('');
  const [nextBid, setNextBid] = useState(0); // Initialize nextBid with 0
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [chainName, setChainName] = useState(null);
  const [bidLogs, setBidLogs] = useState(null); // Initialize bidLogs with null or an appropriate initial value

  useEffect(() => {

    if (window.ethereum) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(accounts => {
          setAccount(accounts[0]);
          setChainId(parseInt(window.ethereum.chainId, 16));
          setChainName(getChainNamefromhex(window.ethereum.chainId));
          
          // Log chain ID and chain name to the console
          console.log('Chain ID:', window.ethereum.chainId);
          console.log('Chain Name:', getChainNamefromhex(window.ethereum.chainId));
        })
        .catch(error => {
          console.error('Error connecting to MetaMask:', error);
        });
  
      window.ethereum.on('chainChanged', newChainId => {
        setChainId(newChainId);
        setChainName(getChainNamefromhex(newChainId));
        
        // Log chain ID and chain name to the console when it changes
        console.log('New Chain ID:', newChainId);
        console.log('New Chain Name:', getChainNamefromhex(newChainId));
  
        // Reload the page when the network changes
        window.location.reload();
      });
    } else {
      console.error('MetaMask not detected.');
    }
  
    
    const getChainNamefromhex = chainId => {
      switch (chainId) {
        case '0x1':
          return 'Mainnet';
        case '0x3':
          return 'Ropsten';
        case '0x4':
          return 'Rinkeby';
        case '0x5':
          return 'Goerli';
        case '0x2a':
          return 'Kovan';
        case '0x13881':
          return 'Polygon Mumbai';
        default:
          return 'Unknown';
      }
    };
  

    const getChainName = chainId => {
      switch (chainId) {
        case '80001':
          return 'Polygon Mumbai';
        default:
          return 'Unknown';
      }
    };


    fetch(`https://api.bidify.app/api/auctions/${id}`)
    .then((response) => response.json())
    .then((data) => {
      console.log('Auction Data received:', data);
      setAuctionData(data.auction);

      // Once auction data is received, fetch bid logs using auctionId
      const auctionId = data.auction.auction_id;
      fetch(`https://api.bidify.app/api/getbidlogs/${auctionId}`)
        .then((response) => response.json())
        .then((bidLogsData) => {
          console.log('Bid Logs Data received:', bidLogsData);
          setBidLogs(bidLogsData);
        })
        .catch((error) => {
          console.error('Error fetching bid logs:', error);
        });
    })
    .catch((error) => {
      console.error('Error fetching auction details:', error);
    });
}, [id]);

if (!auctionData) {
  return <div>Loading...</div>;
}




// Assuming you have web3.js set up and the account variable contains the Ethereum address
// You also have the auctionData.highBidder containing the high bidder's Ethereum address

const isHighestBidder = account && account.toLowerCase() === auctionData.highBidder.toLowerCase();

if (isHighestBidder) {
  console.log("You are the highest bidder.");
  // You can replace the console.log statement with any code to display the message as needed.
}

// Assuming endTime is a hexadecimal string
const hexEndTime = auctionData.endTime;

// Convert hexadecimal to decimal
const decimalEndTime = parseInt(hexEndTime, 16);

// Create a JavaScript Date object
const dateEndTime = new Date(decimalEndTime * 1000); // Multiply by 1000 for milliseconds

// Current time
const currentTime = new Date();

// Check if the auction has ended
const hasEnded = dateEndTime < currentTime;


 // Assuming auctionData.price is in wei as a string or number
 const weiPrice = typeof auctionData.price === 'string' ? window.BigInt(auctionData.price) : window.BigInt(auctionData.price); // Use window.BigInt

 // Convert wei to Ether
 const etherPrice = parseFloat(weiPrice) / 10**18;

 // Format the Ether price to a fixed number of decimal places
 const formattedEtherPrice = etherPrice.toFixed(6); // Adjust the number of decimal places as needed




 const handleBid = async () => {
  try {
    if (hasEnded) {
      // Auction has already ended, inform the user.
      alert('This auction has already ended.');
      return;
    }

    if (window.ethereum) {
      await window.ethereum.enable();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      // Calculate the next valid bid amount using the 'getNextBid' function
      const nextBid = await contract.getNextBid(id);

      // Parse the bid amount from user input
      const bidAmountInEther = parseFloat(bidAmount); // Assuming bidAmount is in Ether

      // Convert the bid amount to Wei
      const bidAmountInWei = ethers.utils.parseEther(bidAmountInEther.toString());

      console.log('Bid Amount in Wei:', bidAmountInWei.toString());

      // Check if the user's bid is greater than or equal to the 'nextBid'
      if (bidAmountInWei < nextBid) {
        alert('Bid amount is too low. Next valid bid: ' + ethers.utils.formatEther(nextBid) + ' ETH');
        return;
      }

      // Place the bid using the 'contract.bid' function
      const transaction = await contract.bid(id, ethers.constants.AddressZero, bidAmountInWei, {
        gasLimit: 500000, // Adjust the gas limit as needed (e.g., increase it)
        gasPrice: ethers.utils.parseUnits('50', 'gwei'), // Adjust the gas price as needed (e.g., change the value and unit)
        value: bidAmountInWei, // Specify the bid amount as the value sent with the transaction
      });

      await transaction.wait();
      console.log('Bid placed successfully.');

      // Refresh auction details after placing a bid
      // fetchAuctionDetails();
    } else {
      console.error('No web3 provider available.');
    }
  } catch (error) {
    console.error('Error placing bid:', error);
    alert('Error placing bid: ' + error.message);
  }
};


const finishAuction = async (auctionId) => {
  try {
    if (window.ethereum) {
      await window.ethereum.enable();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      // Call the 'finish' function with the provided 'auctionId'
      const transaction = await contract.finish(auctionId, {
        gasLimit: 500000, // Adjust the gas limit as needed (e.g., increase it)
        gasPrice: ethers.utils.parseUnits('50', 'gwei'), // Adjust the gas price as needed (e.g., change the value and unit)
      });

      await transaction.wait();
      console.log('Auction finished successfully.');

      // You can optionally refresh auction details or perform other actions after finishing the auction.
    } else {
      console.error('No web3 provider available.');
    }
  } catch (error) {
    console.error('Error finishing auction:', error);
    alert('Error finishing auction: ' + error.message);
  }
};



const handleFinishAuction = async () => {
  try {
    // You need to pass the auction ID or relevant parameters to finishAuction
    // You can retrieve the auction ID from your component's state or props
    const auctionId = auctionData.auction_id;

    // Call the finishAuction function to finish the auction
    await finishAuction(auctionId);
  } catch (error) {
    console.error('Error handling finish auction:', error);
    // Handle the error, display a message, or take appropriate action
  }
};


  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-semibold mb-4">Auction Details</h1>
      <div className="grid grid-cols-2 gap-4">
        {/* Left Side: Image */}
        <div>
          <img
            src={auctionData.tokenURI}
            alt="Auction Item"
            className="main-w-500 min-h-500 rounded-lg mb-4"
            />
     <div className="mb-4">
            <strong>Creator:</strong> {auctionData.creator} Created this auction
          </div>
          {bidLogs === null ? (
  <div className="text-center text-gray-600">Loading bid logs...</div>
) : bidLogs.length === 0 ? (
  <div className="text-center text-gray-600">This auction has no bids yet.</div>
) : (
  <table className="min-w-full border-collapse border border-gray-300">
    <thead>
      <tr className="bg-gray-100">
        <th className="px-4 py-2">Bidder</th>
        <th className="px-4 py-2">Bid Amount</th>
      </tr>
    </thead>
    <tbody>
      {bidLogs.map((log) => (
        <tr key={log.id} className="border-t border-gray-300">
          <td className="px-4 py-2">{log.bidder}</td>
          <td className="px-4 py-2">{log.price}</td>
        </tr>
      ))}
    </tbody>
  </table>
)}


        </div>

        {/* Right Side: Details */}
        <div>
          <div className="mb-4">
            <strong>Platform Name:</strong> {auctionData.platformName}
          </div>
          <div className="mb-4">
            <strong>Platform Symbol:</strong> {auctionData.platformSymbol}
          </div>


          <div className="mb-4">
            <strong>Currency:</strong> {auctionData.currency}
          </div>
          <div className="mb-4">
            <strong>Platform:</strong> {auctionData.platform}
          </div>

          <div className="mb-4">
            <strong>Token id:</strong> {auctionData.token}
          </div>
          <div className="mb-4">
          <strong>Price (ETH):</strong> {formattedEtherPrice} ETH
          </div>
          <div className="mb-4">
            <strong>Time in Days:</strong> {auctionData.timeInDays}
          </div>
          <div className="mb-4">
            <strong>Referrer:</strong> {auctionData.referrer}
          </div>
          <div className="mb-4">
            <strong>Token URI:</strong> {auctionData.tokenURI}
          </div>
          <div className="mb-4">
            <strong>Chain ID:</strong> {auctionData.chainid}
          </div>
          
          <div className="mb-4">
    <strong>Highest Bidder:</strong> {auctionData.highBidder}
    {isHighestBidder ? <p>You are the highest bidder.</p> : null}
  </div>



          <div className="mb-4">
            <strong>Allow Marketplace:</strong>
            {parseInt(auctionData.allowMarketplace) === 1 ? "true" : "false"} 
          </div>
        <div className="mb-4">
        <strong>End Time:</strong> {hasEnded ? 'This auction has ended' : dateEndTime.toLocaleString()}
        </div>
            <div className="mb-4">
            <strong>Paid Out:</strong>
            {parseInt(auctionData.paidOut) === 1 ? "true" : "false"}
            </div>

            <div className="mb-4">
            <strong>Is ERC721:</strong>
            {parseInt(auctionData.isERC721) === 1 ? "true" : "false"}
            </div>
          <div className="mb-4">
            <strong>Chain Name:</strong> {auctionData.chainName}
          </div>

      {/* Auto-populate the bid input field with the calculated nextBid */}
      <div className="mb-4">
        <label htmlFor="bidAmount" className="block font-semibold mb-2">
          Bid Amount (ETH):
        </label>
        <input
          type="text"
          id="bidAmount"
          className="border p-2 w-full"
          placeholder={`Minimum bid: ${formattedEtherPrice} ETH`} // Display the minimum bid amount as a placeholder
          value={bidAmount}
          onChange={(e) => setBidAmount(e.target.value)}
        />
      </div>

      <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={handleBid}>
        Place Bid
      </button>

      <button className="bg-red-500 text-white px-4 py-2 rounded ml-2" onClick={handleFinishAuction}>
       Finish Auction
      </button>
      
        </div>
      </div>
    </div>
  );
}

export default AuctionDetailPage;
