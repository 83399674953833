import React, { useState, useEffect } from 'react';
import { FaEthereum, FaArrowCircleRight } from 'react-icons/fa';
import Modal from 'react-modal';
import { Outlet, Link } from 'react-router-dom';
import axios from 'axios';
import NFTSearch from './NFTSearch';

import maticLogo from './logos/matic.svg'; // Import the SVG file as an image
import bidifyLogo from './logos/bidify.svg'; // Import the SVG file as an image
import metamaskLogo from './logos/metamask.svg'; // Import the SVG file as an image
import ethereumLogo from './logos/ethereum.svg'; // Import the SVG file as an image
import avalancheLogo from './logos/avalanche.svg'; // Import the SVG file as an image
import bnbLogo from './logos/bnb.svg'; // Import the SVG file as an image


const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust overlay background color and opacity
    zIndex: 1000, // Add a z-index value to control the overlay's stacking order
  },
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '600px',
    padding: '20px',
    textAlign: 'center',
  },
};


const customSearchModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust overlay background color and opacity
    zIndex: 1000, // Add a z-index value to control the overlay's stacking order
  },
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjust the width as per your preference
    maxWidth: '700px', // Set a maximum width if needed
    padding: '20px',
    textAlign: 'center',
    position: 'relative', // Add this to enable stacking
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Background color with transparency
    backdropFilter: 'blur(10px)', // Apply the blur effect
    borderRadius: '10px', // Add rounded corners if desired
    maxHeight: '80vh', // Set a maximum height for scrollability
    overflowY: 'auto', // Enable vertical scrolling if content overflows
    zIndex: 1001, // Add a z-index value to control the modal's stacking order
  },
};

const networkConfigurations = [
  {
    name: 'Rinkeby',
    chainId: '0x4',
    rpcUrl: 'https://rinkeby.infura.io/v3/0c8149f8e63b4b818d441dd7f74ab618',
    symbol: 'ETH', // Add the symbol here
    logo: ethereumLogo,
  },
  {
    name: 'BNB Smart Chain Mainnet',
    chainId: '0x38',
    rpcUrl: 'https://bsc-dataseed.binance.org',
    symbol: 'BNB', // Add the symbol here
    logo: bnbLogo,
  },

  {
    name: 'Avalanche C-Chain',
    chainId: '0xa86a',
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    symbol: 'AVAX', // Add the symbol here
    logo: avalancheLogo,
  },
  {
    name: 'Matic',
    chainId: '0x89',
    rpcUrl: 'https://polygon-rpc.com',
    symbol: 'MATIC', // Add the symbol here
    logo: maticLogo,
  },
  {
    name: 'Matic Mumbai',
    chainId: '0x13881',
    rpcUrl: 'https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78',
    symbol: 'MATIC', // Add the symbol here
    logo: maticLogo,
  },
  {
    name: 'EtherGem',
    chainId: '0x7c3',
    rpcUrl: 'https://jsonrpc.egem.io/custom',
    symbol: 'EGEM', // Add the symbol here
    logo: metamaskLogo,
  },
  {
    name: 'Gnosis',
    chainId: '0x64',
    rpcUrl: 'https://rpc.gnosischain.com',
    symbol: 'XDAI', // Add the symbol here
    logo: metamaskLogo,
  },
  {
    name: 'Ethereum Classic Mainnet',
    chainId: '0x3d',
    rpcUrl: 'https://etc.rivet.link',
    symbol: 'ETC', // Add the symbol here
    logo: metamaskLogo,
  },
  {
    name: 'Evmos',
    chainId: '0x2329',
    rpcUrl: 'https://evmos-evm.publicnode.com',
    symbol: 'EVMOS', // Add the symbol here
    logo: metamaskLogo,
  },
  {
    name: 'Moonriver',
    chainId: '0x505',
    rpcUrl: 'https://rpc.api.moonriver.moonbeam.network',
    symbol: 'MOVR', // Add the symbol here
    logo: metamaskLogo,
  },

];



const Navbar = () => {
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [chainName, setChainName] = useState(null);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // State for the search bar
  const [showSearchModal, setShowSearchModal] = useState(false);

  // Define the toggleMobileMenu function
  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(accounts => {
          setAccount(accounts[0]);
          setChainId(window.ethereum.chainId);
          setChainName(getChainNamefromhex(window.ethereum.chainId));
        })
        .catch(error => {
          console.error('Error connecting to MetaMask:', error);
        });
  
      window.ethereum.on('chainChanged', newChainId => {
        setChainId(newChainId);
        setChainName(getChainNamefromhex(newChainId));
        // Reload the page when the chain changes
        window.location.reload();
      });
    } else {
      alert('MetaMask not detected.');
    }
  }, []);
  
  
  const connectToMetaMask = () => {
    if (!account && window.ethereum) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(accounts => {
          setAccount(accounts[0]);
          closeConnectModal();
          // Reload the page after connecting
          window.location.reload();
        })
        .catch(error => {
          console.error('Error connecting to MetaMask:', error);
        });
    } else if (!window.ethereum) {
      console.error('MetaMask not detected.');
    }
  };

  const connectToNetwork = (networkConfig) => {
    console.log('Connecting to network:', networkConfig.name);
  
    if (!window.ethereum) {
      console.error('MetaMask not detected.');
      return;
    }
  
    // Check if an account is available, and request one if not
    if (!account) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(accounts => {
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            // Continue with connecting to the network
            continueConnectingToNetwork(networkConfig);
          } else {
            console.error('No Ethereum account available.');
          }
        })
        .catch(error => {
          console.error('Error connecting to MetaMask:', error);
        });
    } else {
      // An account is available, continue with connecting to the network
      continueConnectingToNetwork(networkConfig);
    }
  };
  
  const continueConnectingToNetwork = (networkConfig) => {
    const targetChainId = networkConfig.chainId;
  
    // Check if the current network is already the target network
    if (chainId === targetChainId) {
      console.log('Already connected to the target network:', networkConfig.name);
      closeConnectModal(); // Close the modal as nothing needs to be done.
      return;
    }
  
    // Switch to the selected network
    window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: targetChainId,
        chainName: networkConfig.name,
        nativeCurrency: {
          name: networkConfig.symbol, // Use the symbol as the currency name
          symbol: networkConfig.symbol, // Use the symbol as the currency symbol
          decimals: 18,
        },
        rpcUrls: [networkConfig.rpcUrl],
        iconUrls: [networkConfig.logo], // Use the logo as the network icon
      }],
    }).then(() => {
      console.log('Connected to network:', networkConfig.name);
      closeConnectModal();
    }).catch(error => {
      console.error('Error connecting to network:', error);
    });
  };
  


    const getChainNamefromhex = chainId => {
      switch (chainId) {
        case '0x1':
          return 'Mainnet';
        case '0x3':
          return 'Ropsten';
        case '0x4':
          return 'Rinkeby';
        case '0x5':
          return 'Goerli';
        case '0x2a':
          return 'Kovan';
        case '0x13881':
          return 'Polygon Mumbai';
        case '0x7c3':
          return 'EtherGem';
        case '0xa86a':
          return 'Avalanche C-Chain';
        case '0x89':
          return 'Matic';
        case '0x2329':
          return 'Evmos';
        case '0x505':
          return 'Moonriver';
        case '0x38':
          return 'BNB Smart Chain Mainnet';
        case '0x64':
          return 'Gnosis';
        case '0x3d':
          return 'Ethereum Classic Mainnet';
        default:
          return 'Unknown';
      }
    };



  const openConnectModal = () => {
    setShowConnectModal(true);
  };

  const closeConnectModal = () => {
    setShowConnectModal(false);
  };

  const openAccountModal = () => {
    setShowAccountModal(true);
  };

  const closeAccountModal = () => {
    setShowAccountModal(false);
  };

  // Function to open the search modal
  const openSearchModal = () => {
    setShowSearchModal(true);
  };

  // Function to close the search modal
  const closeSearchModal = () => {
    setShowSearchModal(false);
  };
  
    // Function to fetch data using Axios
    const fetchAuctionsData = async (chainId) => {
      try {
        const response = await axios.get(`http://localhost:3000/api/getallauctions/${chainId}`);
        console.log('Auctions Data:', response.data);
      } catch (error) {
        console.error('Error fetching auctions data:', error);
      }
    };

 


  return (
    <div className="container mx-auto border-b border-gray-300 p-4">
      {/* Desktop Navigation Bar */}
      <div>
      <nav className="hidden md:flex justify-between items-center p-4 bg-white text-black">
      <div className="flex items-center">
        <img src={bidifyLogo} alt="Bidify Logo" className="h-10 w-10" />
        <Link to="/" className="text-2xl font-bold text-gray hover:text-gray-300">
          Bidify App
        </Link>
      </div>


    `  {/* Updated Search Bar */}
      <div className="relative">
        <input
          type="text"
          placeholder="Search..."
          className="border rounded-full py-2 px-4 w-64" // Adjust width and styling here
          readOnly
          onClick={openSearchModal}
        />
        <button
          className="absolute right-2 top-1/2 transform -translate-y-1/2"
          onClick={() => {
            // Handle search here
            console.log(`Searching for: ${searchQuery}`);
          }}
        >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
        </svg>
        </button>
      </div>


        <div className="flex items-center space-x-4">
          {/* Navigation Links */}
          <ul className="flex space-x-4">
          <li>
            <Link to="/" className="text-black hover:text-gray-700 hover:bg-gray-200 rounded-full py-2 px-4 font-bold">
              Home
            </Link>
          </li>
          <li>
            <Link to="/mintcollection" className="text-black hover:text-gray-700 hover:bg-gray-200 rounded-full py-2 px-4 font-bold">
              Mint Collection
            </Link>
          </li>
          <li>
            <Link to="/mycollections" className="text-black hover:text-gray-700 hover:bg-gray-200 rounded-full py-2 px-4 font-bold">
              My Collections
            </Link>
          </li>
          <li>
            <Link to="/auctions" className="text-black hover:text-gray-700 hover:bg-gray-200 rounded-full py-2 px-4 font-bold">
              Auctions
            </Link>
          </li>
          <li>
            <Link to="/mybids" className="text-black hover:text-gray-700 hover:bg-gray-200 rounded-full py-2 px-4 font-bold">
              My Bids
            </Link>
          </li>
        </ul>




    {/* Account Status */}
          {account && (
            <div
              className="cursor-pointer flex items-center"
              onClick={openAccountModal}
            >
              <span className="inline-flex items-center px-2 py-2 rounded-full bg-gray-950 text-white text-md">
              {chainId === '0x13881' && (
                <div className="">
                <img src={maticLogo} alt="Matic Logo" className="h-8 w-8 mr-2" />
                </div>
              )}
              {chainId === '0x89' && (
                <div className="">
                <img src={maticLogo} alt="Matic Logo" className="h-8 w-8 mr-2" />
                </div>
              )}
             {chainId === '0xa86a' && (
                <div className="">
                <img src={avalancheLogo} alt="Matic Logo" className="h-8 w-8 mr-2" />
                </div>
              )}
             {chainId === '0x38' && (
                <div className="">
                <img src={bnbLogo} alt="Matic Logo" className="h-8 w-8 mr-2" />
                </div>
              )}
              {account.slice(0, 10)}...
              </span>
            </div>
          )}

        {!account && (
          <button
            className="bg-gray-800 text-white hover:bg-gray-600 py-2 px-4 rounded-lg cursor-pointer flex items-center"
            onClick={openConnectModal}
          >
            <img src={metamaskLogo} alt="metamask Logo" className="h-8 w-8 mr-2" />
            <span>Connect Metamask</span>
          </button>
        )}


          {/* Connect Modal */}
          <Modal
            isOpen={showConnectModal}
            onRequestClose={closeConnectModal}
            style={customModalStyles}
          >
    <h1 className='mt-5'>Connect to a  Network</h1>

{networkConfigurations.map((config, index) => (
  <div key={index} className="network-button">
    <button
      onClick={() => connectToNetwork(config)}
      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full mb-4 inline-flex items-center space-x-2"
    >
      <img src={config.logo} alt={`${config.name} Logo`} width="24" height="24" />
      <span>Connect to {config.name}</span>
    </button>
  </div>
))}
          </Modal>



          <Modal
  isOpen={showAccountModal}
  onRequestClose={closeAccountModal}
  style={customModalStyles}
>
<h2 className="text-3xl font-bold text-gray-900 mb-6">Account Details</h2>
{account && (
  <div className="bg-gray-200 p-6 rounded-lg shadow-lg">
    <p className="text-gray-700">Account Address:</p>
    <p className="text-gray-900 font-bold">
      {account.length > 10 ? `${account.slice(0, 5)}...${account.slice(-5)}` : account}
    </p>
    <p className="text-gray-700 mt-4">Chain ID:</p>
    <p className="text-gray-900 font-bold">{chainId}</p>
    <p className="text-gray-700 mt-4">Chain:</p>
    <p className="text-gray-900 font-bold">{chainName}</p>
  </div>
)}


  <h1 className='mt-5'>Switch Network</h1>

{networkConfigurations.map((config, index) => (
  <div key={index} className="network-button">
    <button
      onClick={() => connectToNetwork(config)}
      className="w-full bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-full mb-4 inline-flex items-center space-x-2"
    >
      <img src={config.logo} alt={`${config.name} Logo`} width="24" height="24" />
      <span>Connect to {config.name}</span>
    </button>
  </div>
))}

</Modal>


        </div>
      </nav>

    {/* Mobile Navigation Bar */}
{/* Mobile Navigation Bar */}
<nav className="md:hidden md:flex justify-between items-center p-4 bg-white text-black"> 

  <div className="flex justify-between items-center">
    <div className="flex items-center"> {/* Wrap the menu button and address in a flex container */}
      <div>
        <span className="text-2xl font-bold">Bidify App</span>
      </div>
    </div>
    <div className="flex items-center space-x-4"> {/* Create a flex container for the menu icon and address */}
                {/* Search Icon */}
            <div
              className="cursor-pointer"
              onClick={openSearchModal}
            >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
            </div>
            {account && (
            <div
              className="cursor-pointer flex items-center"
              onClick={openAccountModal}
            >
              <span className="inline-flex items-center px-1 py-1 rounded-full bg-gray-950 text-white text-sm">
              {chainId === '0x13881' && (
                <div className="">
                <img src={maticLogo} alt="Matic Logo" className="h-6 w-6 ml-2 mr-2" />
                </div>
              )}
              {account.slice(0, 10)}...
              </span>
            </div>
          )}

      {/* Menu Icon */}
      <div
        className="cursor-pointer"
        onClick={toggleMobileMenu}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </div>
    </div>
  </div>
</nav>

    {/* Search Modal */}
    <Modal
        isOpen={showSearchModal}
        onRequestClose={closeSearchModal}
        style={customSearchModalStyles}
      >
        <div>
    {chainId !== null ? (
      <NFTSearch chainId={chainId} />
    ) : (
      <p>Loading...</p>
    )}
  </div>
      </Modal>

{/* Mobile Menu Modal */}
<Modal
  isOpen={showMobileMenu}
  onRequestClose={() => setShowMobileMenu(false)}
  style={customModalStyles}
>
  <div className="mobile-menu-container">
    {/* Add your mobile menu items here */}
    <ul className="text-center">
    
      <li>
        <Link to="/" className="text-gray-500 text-xl font-bold hover:underline">
          Home
        </Link>
      </li>
      <li>
        <Link to="/mintcollection" className="text-gray-500 text-xl font-bold hover:underline">
          Mint Collection
        </Link>
      </li>
      <li>
        <Link to="/mycollections" className="text-gray-500 text-xl font-bold hover:underline">
          My Collections
        </Link>
      </li>
      <li>
        <Link to="/mybids" className="text-gray-500 text-xl font-bold hover:underline">
          My Bids
        </Link>
      </li>
      
    </ul>
  </div>
</Modal>


      <Outlet /> {/* Render the content of the currently active route */}
    </div>
    </div>
  );
};

export default Navbar;
