import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import BidifyMarket from './jsons/BidifyMarket.json';
import { Web3Storage } from 'web3.storage';
import { Outlet, Link } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import NFTList from './NFTList';
import NFTBanner from './NFTBanner';

      


const contractAddress = '0xc851f209015b323AA67e7b1325148E1079e4D205'; // Replace with your contract address
const abi = BidifyMarket.abi; // Use the ABI from the imported JSON file

function AuctionList() {
  const [auctions, setAuctions] = useState([]);
  const web3StorageToken = 'your_web3storage_token_here'; // Replace with your actual Web3Storage token
  const web3StorageClient = new Web3Storage({ token: web3StorageToken });
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [chainName, setChainName] = useState(null);

  
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(accounts => {
          setAccount(accounts[0]);
          setChainId(window.ethereum.chainId);
          setChainName(getChainNamefromhex(window.ethereum.chainId));
        })
        .catch(error => {
      //    alert('Error connecting to MetaMask: ' + error);
        });
  
      window.ethereum.on('chainChanged', newChainId => {
        setChainId(newChainId);
        setChainName(getChainNamefromhex(newChainId));
        window.location.reload();
      });
    } else {
      alert('MetaMask not detected.');
    }
  
    
    const getChainNamefromhex = chainId => {
      switch (chainId) {
        case '0x1':
          return 'Mainnet';
        case '0x3':
          return 'Ropsten';
        case '0x4':
          return 'Rinkeby';
        case '0x5':
          return 'Goerli';
        case '0x2a':
          return 'Kovan';
        case '0x13881':
          return 'Polygon Mumbai';
        default:
          return 'Unknown';
      }
    };
  

 
    async function fetchAuctions() {
      try {
        if (window.ethereum) {
          await window.ethereum.enable(); // Request user permission to connect
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const network = await provider.getNetwork(); // Get the network information
          const chainId = window.ethereum.chainId;
          const chainName = getChainNamefromhex(chainId);

          const contract = new ethers.Contract(contractAddress, abi, provider);
          const eventFilter = contract.filters.ListingCreated();
          const logs = await provider.getLogs({
            fromBlock: 0,
            toBlock: 'latest',
            topics: eventFilter.topics,
          });
          console.log(logs);
          const formattedAuctions = [];
    
          for (const log of logs) {
            const decodedLog = contract.interface.parseLog(log);
            const auctionId = decodedLog.args.id.toNumber();
            const platformContract = new ethers.Contract(decodedLog.args.platform, ['function name() view returns (string)', 'function symbol() view returns (string)', 'function tokenURI(uint256 tokenId) view returns (string)'], provider);
            const platformName = await platformContract.name();
            const platformSymbol = await platformContract.symbol();
            const tokenURI = await getCidTokenUri(platformContract, decodedLog.args.token);


            // Fetch auction details for each auction ID
            const auctionDetails = await contract.getListing(auctionId);
            console.log('Auction Details:', auctionDetails);
    
          // Create an object with auction details
          const formattedAuction = {
            id: decodedLog.args.id.toNumber(),
            token: decodedLog.args.token.toString(),
            currency: decodedLog.args.currency,
            platform: auctionDetails[2],
            platformName: platformName,
            platformSymbol: platformSymbol,
            creator: decodedLog.args.creator,
            price: auctionDetails[4]._hex,
            timeInDays: decodedLog.args.timeInDays,
            referrer: auctionDetails[7],
            tokenURI: tokenURI,
            highBidder: auctionDetails[8],
            allowMarketplace: auctionDetails[6],
            endTime : auctionDetails[9]._hex,
            paidOut: auctionDetails[10],
            isERC721: auctionDetails[11],
          };

      
          formattedAuctions.push(formattedAuction);

          // Insert data into the database for each formatted auction
          const dataToInsert = {
            auction_id: decodedLog.args.id.toNumber(),
            creator: formattedAuction.creator,
            currency: formattedAuction.currency, // Add the same property as in formattedAuction
            platform: formattedAuction.platform,
            platformName: formattedAuction.platformName, // Add the same property as in formattedAuction
            platformSymbol: formattedAuction.platformSymbol, // Add the same property as in formattedAuction
            token: formattedAuction.token,
            price: formattedAuction.price,
            timeInDays: formattedAuction.timeInDays,
            referrer: formattedAuction.referrer,
            tokenURI: formattedAuction.tokenURI,
            chainid: chainId, // Insert the chain ID here
            chainName: chainName, // Insert the chain ID here
            highBidder: formattedAuction.highBidder,
            allowMarketplace: formattedAuction.allowMarketplace,
            endTime: formattedAuction.endTime,
            paidOut: formattedAuction.paidOut,
            isERC721: formattedAuction.isERC721,
          };


            try {
              const response = await axios.post('https://api.bidify.app/api/insertauctions', dataToInsert);
              //  console.log(response.data.message); // Server response message
            } catch (error) {
              //    console.error('Error inserting data:', error);
            }
          }
    
          setAuctions(formattedAuctions); // Update state with fetched and formatted data
        } else {
          console.error('No web3 provider available.');
        }
      } catch (error) {
        console.error('Error fetching auctions:', error);
      }
    }
    
    fetchAuctions();
    
  }, []);

  const getCidTokenUri = async (platformContract, tokenId) => {
    try {
      const tokenURI = await platformContract.tokenURI(tokenId);
      const response = await web3StorageClient.get(tokenURI);
      const files = await response.files();
      const firstFile = files[0];

      if (firstFile) {
        return `https://${firstFile.cid}.ipfs.w3s.link/`;
      } else {
        console.error("No file found in the CID.");
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };


return (
  
  <div>
    <NFTBanner chainId={chainId} />
    {chainId !== null ? (
      <NFTList chainId={chainId} />
    ) : (
      <p>Loading...</p>
    )}
  </div>
);
}

export default AuctionList;
