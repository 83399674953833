import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


// Assuming the price is in wei
const WEI_TO_ETH = 1e-18;

  // Function to convert wei to ETH decimal format
  const weiToEth = (wei) => {
    return (wei * WEI_TO_ETH).toFixed(4); // Adjust the number of decimal places as needed
  };

const NFTList = ({ chainId }) => {
  const [nfts, setNFTs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(''); // State for the search query

  useEffect(() => {
    // Make a GET request to your API endpoint with the chainId
    axios.get(`https://api.bidify.app/api/getallauctions/${chainId}`)
      .then((response) => {
        const uniqueNFTs = removeDuplicates(response.data.auctions, 'auction_id');
        setNFTs(uniqueNFTs);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false on error as well
      });
  }, [chainId]);

  // Function to remove duplicates based on a specific key
  const removeDuplicates = (array, key) => {
    const seen = new Set();
    return array.filter((item) => {
      const value = item[key];
      if (seen.has(value)) {
        return false;
      }
      seen.add(value);
      return true;
    });
  };

  // Function to handle changes in the search query
  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter NFTs based on the search query
  const filteredNFTs = nfts.filter((nft) =>
    nft.platformName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  
  return (
    <div className="container mx-auto p-4">
<div className="flex items-center justify-between mb-4 mt-4 ">
  <h1 className="text-3xl font-semibold">NFT List</h1>
  <div className="relative flex items-center">
    <input
      type="text"
      placeholder="Search NFTs by platform name..."
      className="border border-gray-300 rounded-md px-4 py-2 pr-10"
      value={searchQuery}
      onChange={handleSearchQueryChange}
    />
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="absolute right-3 top-1/2 transform -translate-y-1/2 w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
    </svg>
  </div>
</div>

      {loading ? (
        <div className="text-center">
          <i className="fas fa-spinner fa-spin text-4xl text-gray-500"></i>
         <div className="flex items-center justify-center h-screen">
  <p className="text-center">Loading...</p>
</div>

        </div>
      ) : filteredNFTs.length === 0 ? (
        <p>No NFTs found in this network.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {filteredNFTs.map((nft) => (
            <div
              key={nft.auction_id}
              className="bg-white rounded-lg shadow-lg p-4"
            >
              <img
                src={nft.tokenURI}
                alt={`Image for ${nft.platformName}`}
                className="mb-3  w-full rounded-xl h-48"
              />
              <div className="mb-3 flex items-center justify-between px-1 md:items-start">
  <div className="mb-2">
    <p className="text-lg font-bold text-navy-700"> {nft.platformName} ({nft.platformSymbol} )</p>
    <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
  By {nft.creator.length > 10 ? nft.creator.substring(0, 10) + '...' : nft.creator}{" "}
</p>
   
  </div>
</div>
                    <div class="flex items-center justify-between md:items-center lg:justify-between ">
                        <div class="flex">
                        <p className="!mb-0 text-sm font-bold text-brand-500">
                         Current Bid: <span>{nft.current_bid !== null ? nft.current_bid : 'No bids'}</span>
                         </p>                        
                         </div>
                        <Link to={`/auction/${nft.auction_id}`} className="inline-block">
                          <button class="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-purple-600 hover:to-blue-600 text-white font-medium py-2 px-4 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out">
                            Place Bid
                          </button>
                        </Link>

                    </div>
               <p className="text-gray-600">Price: {weiToEth(nft.price)} ETH</p>
               <p className="text-gray-600">Auction {new Date().getTime() / 1000 > parseInt(nft.endTime, 16) ? "ended" : `endtime: ${(() => {
                const hexTimestamp = nft.endTime;
                const decimalTimestamp = parseInt(hexTimestamp, 16);
                const humanDate = new Date(decimalTimestamp * 1000).toGMTString();
                return humanDate;
              })()} days`}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NFTList;
